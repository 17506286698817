"use strict";
function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _type_of(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = function(target, all) {
    for(var name in all)__defProp(target, name, {
        get: all[name],
        enumerable: true
    });
};
var __copyProps = function(to, from, except, desc) {
    if (from && (typeof from === "undefined" ? "undefined" : _type_of(from)) === "object" || typeof from === "function") {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            var _loop = function() {
                var key = _step.value;
                if (!__hasOwnProp.call(to, key) && key !== except) __defProp(to, key, {
                    get: function() {
                        return from[key];
                    },
                    enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
                });
            };
            for(var _iterator = __getOwnPropNames(from)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true)_loop();
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }
    return to;
};
var __toESM = function(mod, isNodeMode, target) {
    return target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(// If the importer is in node compatibility mode or this is not an ESM
    // file that has been converted to a CommonJS file using a Babel-
    // compatible transform (i.e. "__esModule" has not been set), then set
    // "default" to the CommonJS "module.exports" for node compatibility.
    isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", {
        value: mod,
        enumerable: true
    }) : target, mod);
};
var __toCommonJS = function(mod) {
    return __copyProps(__defProp({}, "__esModule", {
        value: true
    }), mod);
};
// src/index.ts
var src_exports = {};
__export(src_exports, {
    AppOperations: function() {
        return AppOperations;
    },
    BrowsableMenuOperations: function() {
        return BrowsableMenuOperations;
    },
    CheckoutOperations: function() {
        return CheckoutOperations;
    },
    CheckoutSlice: function() {
        return CheckoutSlice;
    },
    HandoffOperations: function() {
        return HandoffOperations;
    },
    HandoffSlice: function() {
        return HandoffSlice;
    },
    LocationOperations: function() {
        return LocationOperations;
    },
    MenuOperations: function() {
        return MenuOperations;
    },
    SeatingOperations: function() {
        return SeatingOperations;
    },
    StoreWrapper: function() {
        return StoreWrapper;
    },
    UserOperations: function() {
        return UserOperations;
    },
    appActions: function() {
        return appActions;
    },
    appSlice: function() {
        return appSlice;
    },
    browsableMenuActions: function() {
        return browsableMenuActions;
    },
    browsableMenuSlice: function() {
        return browsableMenuSlice;
    },
    checkoutActions: function() {
        return checkoutActions;
    },
    getOptionContext: function() {
        return getOptionContext;
    },
    getSubOptions: function() {
        return getSubOptions;
    },
    handoffActions: function() {
        return handoffActions;
    },
    locationActions: function() {
        return locationActions;
    },
    locationSlice: function() {
        return locationSlice;
    },
    menuActions: function() {
        return menuActions;
    },
    menuSlice: function() {
        return menuSlice;
    },
    normalizeAllOptions: function() {
        return normalizeAllOptions;
    },
    persistor: function() {
        return persistor;
    },
    seatingActions: function() {
        return seatingActions;
    },
    seatingSlice: function() {
        return seatingSlice;
    },
    store: function() {
        return store;
    },
    userActions: function() {
        return userActions;
    },
    userSlice: function() {
        return userSlice;
    }
});
module.exports = __toCommonJS(src_exports);
// src/store.ts
var import_toolkit9 = require("@reduxjs/toolkit");
var import_redux_persist2 = require("redux-persist");
var import_storage = __toESM(require("redux-persist/lib/storage"));
var import_redux_thunk = __toESM(require("redux-thunk"));
var import_next_redux_wrapper8 = require("next-redux-wrapper");
var import_redux_state_sync = require("redux-state-sync");
// src/slicers/Checkout.slice.ts
var import_toolkit = require("@reduxjs/toolkit");
var import_next_redux_wrapper = require("next-redux-wrapper");
var initialState = {
    basket: null,
    giftCard: [],
    itemsNotTransferred: null,
    billingScheme: null,
    basketCreationDate: null,
    lastOrder: null,
    curbsideInfo: null,
    signUpOption: null
};
var CheckoutSlice = (0, import_toolkit.createSlice)({
    name: "checkout",
    initialState: initialState,
    reducers: {
        setBasket: function(state, param) {
            var payload = param.payload;
            state.basket = payload;
        },
        setGiftCard: function(state, param) {
            var payload = param.payload;
            state.giftCard = payload;
        },
        setItemsNotTransferred: function(state, param) {
            var payload = param.payload;
            state.itemsNotTransferred = payload;
        },
        clearItemsNotTransferred: function(state) {
            state.itemsNotTransferred = null;
        },
        setBillingScheme: function(state, param) {
            var payload = param.payload;
            state.billingScheme = payload;
        },
        setBasketCreationDate: function(state, param) {
            var payload = param.payload;
            state.basketCreationDate = payload;
        },
        setLastOrder: function(state, param) {
            var payload = param.payload;
            state.lastOrder = payload;
        },
        setCurbsideInfo: function(state, param) {
            var payload = param.payload;
            state.curbsideInfo = payload;
        },
        setSignUpOption: function(state, param) {
            var payload = param.payload;
            state.signUpOption = payload;
        },
        resetCheckout: function() {
            return initialState;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var checkoutActions = CheckoutSlice.actions;
// src/slicers/Handoff.slice.ts
var import_toolkit2 = require("@reduxjs/toolkit");
var import_next_redux_wrapper2 = require("next-redux-wrapper");
var import_entity = require("@maverick/entity");
var initialState2 = {
    selectedMethod: import_entity.HandoffMethods.Curbside,
    restaurantList: [],
    selectedRestaurant: null,
    selectedRestaurantAddress: "",
    deliveryAddress: null,
    searchedAddresses: null
};
var HandoffSlice = (0, import_toolkit2.createSlice)({
    name: "handoff",
    initialState: initialState2,
    reducers: {
        setSelectedMethod: function(state, param) {
            var payload = param.payload;
            state.selectedMethod = payload;
        },
        setRestaurantList: function(state, param) {
            var payload = param.payload;
            state.restaurantList = payload;
        },
        setSelectedRestaurant: function(state, param) {
            var payload = param.payload;
            state.selectedRestaurant = payload;
        },
        setSelectedRestaurantAddress: function(state, param) {
            var payload = param.payload;
            state.selectedRestaurantAddress = payload;
        },
        setDeliveryAddress: function(state, param) {
            var payload = param.payload;
            state.deliveryAddress = payload;
        },
        setSearchedAddress: function(state, param) {
            var payload = param.payload;
            state.searchedAddresses = payload;
        },
        resetHandoff: function() {
            return initialState2;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper2.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var handoffActions = HandoffSlice.actions;
// src/slicers/Menu.slice.ts
var import_toolkit3 = require("@reduxjs/toolkit");
var import_next_redux_wrapper3 = require("next-redux-wrapper");
var import_entity2 = require("@maverick/entity");
var initialState3 = {
    menu: null,
    selectedProduct: null,
    optionGroups: [],
    rawOptionGroups: [],
    selectedOptions: [],
    editedProduct: null,
    redirectRouteMenu: null,
    addCartOrigin: ""
};
var deepClone = function(obj) {
    return structuredClone(obj);
};
var normalizeOptionGroups = function(original, selectedOptions) {
    var originalClone = deepClone(original);
    var normalized = new Array();
    var normalizeMasterOptionGroup = function(optionGroup) {
        var isRoot = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        var optionGroupClone = deepClone(optionGroup);
        optionGroup.options.forEach(function(o) {
            return o.modifiers = null;
        });
        if (isRoot) normalized.push(optionGroup);
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = optionGroupClone.options[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var option = _step.value;
                if (selectedOptions.includes(option.id)) {
                    var _option_modifiers;
                    var optionGroupAlreadyAdded = normalized.find(function(og) {
                        return og.id === optionGroup.id;
                    });
                    if (!optionGroupAlreadyAdded) normalized.push(optionGroup);
                    (_option_modifiers = option.modifiers) === null || _option_modifiers === void 0 ? void 0 : _option_modifiers.forEach(function(modifier) {
                        if (!import_entity2.OptionGroup.IsMasterOptionGroup(optionGroup)) {
                            normalized.push(modifier);
                        } else {
                            normalizeMasterOptionGroup(modifier);
                        }
                    });
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    };
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = originalClone[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var optionGroup = _step.value;
            if (!import_entity2.OptionGroup.IsMasterOptionGroup(optionGroup)) {
                normalized.push(optionGroup);
                continue;
            }
            normalizeMasterOptionGroup(optionGroup, true);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return normalized;
};
var normalizeAllOptions = function(original) {
    var originalClone = deepClone(original);
    var normalized = new Array();
    var normalizeMasterOptionGroup = function(optionGroup) {
        var isRoot = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        var optionGroupClone = deepClone(optionGroup);
        optionGroup.options.forEach(function(o) {
            return o.modifiers = null;
        });
        if (isRoot) {
            normalized.push(optionGroup);
        }
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = optionGroupClone.options[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var option = _step.value;
                var _option_modifiers;
                var optionGroupAlreadyAdded = normalized.find(function(og) {
                    return og.id === optionGroup.id;
                });
                if (!optionGroupAlreadyAdded) {
                    normalized.push(optionGroup);
                }
                (_option_modifiers = option.modifiers) === null || _option_modifiers === void 0 ? void 0 : _option_modifiers.forEach(function(m) {
                    if (!import_entity2.OptionGroup.IsMasterOptionGroup(optionGroup)) normalized.push(m);
                    else normalizeMasterOptionGroup(m);
                });
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    };
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = originalClone[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var optionGroup = _step.value;
            if (!import_entity2.OptionGroup.IsMasterOptionGroup(optionGroup)) {
                normalized.push(optionGroup);
                continue;
            }
            normalizeMasterOptionGroup(optionGroup, true);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return normalized;
};
var getOptionContext = function(optionGroups, id) {
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = optionGroups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var og = _step.value;
            var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
            try {
                for(var _iterator1 = og.options[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                    var o = _step1.value;
                    var _o_modifiers;
                    if (o.id === id) {
                        return {
                            optionGroup: og,
                            option: o
                        };
                    }
                    if ((_o_modifiers = o.modifiers) === null || _o_modifiers === void 0 ? void 0 : _o_modifiers.length) {
                        var context = getOptionContext(o.modifiers, id);
                        if (context) return context;
                    }
                }
            } catch (err) {
                _didIteratorError1 = true;
                _iteratorError1 = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                        _iterator1.return();
                    }
                } finally{
                    if (_didIteratorError1) {
                        throw _iteratorError1;
                    }
                }
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return null;
};
var getSubOptions = function(option) {
    var subOptions = new Array();
    var run = function(option2) {
        var _option2_modifiers;
        if (!((_option2_modifiers = option2.modifiers) === null || _option2_modifiers === void 0 ? void 0 : _option2_modifiers.length)) return;
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = option2.modifiers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var m = _step.value;
                var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
                try {
                    for(var _iterator1 = m.options[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                        var o = _step1.value;
                        subOptions.push(o.id);
                        getSubOptions(o);
                    }
                } catch (err) {
                    _didIteratorError1 = true;
                    _iteratorError1 = err;
                } finally{
                    try {
                        if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                            _iterator1.return();
                        }
                    } finally{
                        if (_didIteratorError1) {
                            throw _iteratorError1;
                        }
                    }
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    };
    run(option);
    return subOptions;
};
var menuSlice = (0, import_toolkit3.createSlice)({
    name: "menu",
    initialState: initialState3,
    reducers: {
        setSelectedProduct: function(state, param) {
            var payload = param.payload;
            state.selectedProduct = payload;
        },
        setRestaurantMenu: function(state, param) {
            var payload = param.payload;
            state.menu = payload;
        },
        setOptionGroups: function(state, param) {
            var payload = param.payload;
            var normalizedOptionGroups = normalizeOptionGroups(payload, state.selectedOptions);
            state.optionGroups = normalizedOptionGroups;
            state.rawOptionGroups = payload;
        },
        selectOption: function(state, param) {
            var payload = param.payload;
            var id = payload;
            if (!id) {
                state.selectedOptions = initialState3.selectedOptions;
                state.optionGroups = initialState3.optionGroups;
                return;
            }
            var _selectedOptions = _to_consumable_array((0, import_toolkit3.current)(state.selectedOptions));
            var context = getOptionContext((0, import_toolkit3.current)(state.rawOptionGroups), id);
            if (!context) {
                return;
            }
            var isMultiSelect = import_entity2.OptionGroup.IsMultiSelect(context.optionGroup);
            var items = context.optionGroup.options.map(function(o) {
                return o.id;
            });
            var isSelected = _selectedOptions.includes(id);
            if (isSelected) {
                if (isMultiSelect) {
                    var _context_option_modifiers;
                    _selectedOptions = _selectedOptions.filter(function(i) {
                        return i !== id;
                    });
                    if ((_context_option_modifiers = context.option.modifiers) === null || _context_option_modifiers === void 0 ? void 0 : _context_option_modifiers.length) {
                        var subOptions = getSubOptions(context.option);
                        _selectedOptions = _selectedOptions.filter(function(i) {
                            return !subOptions.includes(i);
                        });
                    }
                } else if (!context.optionGroup.mandatory) {
                    context.optionGroup.options.forEach(function(o) {
                        var subOptions = getSubOptions(o);
                        _selectedOptions = _selectedOptions.filter(function(o2) {
                            return !subOptions.includes(o2);
                        });
                    });
                    _selectedOptions = _selectedOptions.filter(function(i) {
                        return !items.includes(i);
                    });
                }
            } else {
                if (!isMultiSelect) {
                    context.optionGroup.options.forEach(function(o) {
                        var subOptions = getSubOptions(o);
                        _selectedOptions = _selectedOptions.filter(function(o2) {
                            return !subOptions.includes(o2);
                        });
                    });
                    _selectedOptions = _selectedOptions.filter(function(i) {
                        return !items.includes(i);
                    });
                }
                _selectedOptions.push(id);
            }
            var optionGroups = normalizeOptionGroups((0, import_toolkit3.current)(state.rawOptionGroups), _selectedOptions);
            state.selectedOptions = _selectedOptions;
            state.optionGroups = optionGroups;
        },
        resetCustomize: function(state) {
            state.selectedProduct = initialState3.selectedProduct;
            state.selectedOptions = initialState3.selectedOptions;
            state.editedProduct = initialState3.editedProduct;
            state.optionGroups = initialState3.optionGroups;
        },
        setEditedOptions: function(state, param) {
            var payload = param.payload;
            state.selectedOptions = payload;
        },
        setEditedProduct: function(state, param) {
            var payload = param.payload;
            state.editedProduct = payload;
        },
        setRedirectRouteMenu: function(state, param) {
            var payload = param.payload;
            state.redirectRouteMenu = payload;
        },
        setAddCartOrigin: function(state, param) {
            var payload = param.payload;
            state.addCartOrigin = payload;
        },
        resetMenu: function() {
            return initialState3;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper3.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var menuActions = menuSlice.actions;
// src/slicers/User.slice.ts
var import_toolkit4 = require("@reduxjs/toolkit");
var import_next_redux_wrapper4 = require("next-redux-wrapper");
var initialState4 = {
    userRewards: void 0,
    userRedeemableRewards: void 0,
    userRecentOrders: null,
    userFaves: null,
    userFaveProducts: null,
    userInfo: null,
    userLogged: false,
    userAuthLastInteraction: null
};
var userSlice = (0, import_toolkit4.createSlice)({
    name: "user",
    initialState: initialState4,
    reducers: {
        setUserRewards: function(state, param) {
            var payload = param.payload;
            state.userRewards = payload;
        },
        setUserRedeemableRewards: function(state, param) {
            var payload = param.payload;
            state.userRedeemableRewards = payload;
        },
        setUserRecentOrders: function(state, param) {
            var payload = param.payload;
            state.userRecentOrders = payload;
        },
        setUserFaves: function(state, param) {
            var payload = param.payload;
            state.userFaves = payload;
        },
        setUserFaveProducts: function(state, param) {
            var payload = param.payload;
            state.userFaveProducts = payload;
        },
        setUserInfo: function(state, param) {
            var payload = param.payload;
            state.userInfo = payload;
        },
        setUserLogged: function(state, param) {
            var payload = param.payload;
            state.userLogged = payload;
        },
        setUserAuthLastInteraction: function(state, param) {
            var payload = param.payload;
            state.userAuthLastInteraction = payload;
        },
        resetUser: function() {
            return initialState4;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper4.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var userActions = userSlice.actions;
// src/slicers/App.slice.ts
var import_toolkit5 = require("@reduxjs/toolkit");
var import_next_redux_wrapper5 = require("next-redux-wrapper");
var initialState5 = {
    loading: false,
    processing: false,
    redirectRoute: null,
    redirectRouteRewards: null,
    redirectRouteCheckout: null,
    redirectRouteEdit: null,
    userToken: null,
    showSummary: false,
    showHowItWorks: false,
    auth0Token: null,
    allowDelivery: false,
    featureFlagItems: [],
    featureHighlight: []
};
var appSlice = (0, import_toolkit5.createSlice)({
    name: "app",
    initialState: initialState5,
    reducers: {
        setLoading: function(state, param) {
            var payload = param.payload;
            state.loading = payload;
        },
        setProcessing: function(state, param) {
            var payload = param.payload;
            state.processing = payload;
        },
        setRedirectRoute: function(state, param) {
            var payload = param.payload;
            state.redirectRoute = payload;
        },
        clearRedirectRoute: function(state) {
            state.redirectRoute = initialState5.redirectRoute;
        },
        setRedirectRouteRewards: function(state, param) {
            var payload = param.payload;
            state.redirectRouteRewards = payload;
        },
        clearRedirectRouteRewards: function(state) {
            state.redirectRouteRewards = initialState5.redirectRouteRewards;
        },
        setRedirectRouteCheckout: function(state, param) {
            var payload = param.payload;
            state.redirectRouteCheckout = payload;
        },
        clearRedirectRouteCheckout: function(state) {
            state.redirectRouteCheckout = initialState5.redirectRouteCheckout;
        },
        setRedirectRouteEdit: function(state, param) {
            var payload = param.payload;
            state.redirectRouteEdit = payload;
        },
        clearRedirectRouteEdit: function(state) {
            state.redirectRouteEdit = initialState5.redirectRouteEdit;
        },
        setUserToken: function(state, param) {
            var payload = param.payload;
            state.userToken = payload;
        },
        clearUserToken: function(state) {
            state.userToken = initialState5.userToken;
        },
        setShowSummary: function(state, param) {
            var payload = param.payload;
            state.showSummary = payload;
        },
        setShowHowItWorks: function(state, param) {
            var payload = param.payload;
            state.showHowItWorks = payload;
        },
        setAuth0Token: function(state, param) {
            var payload = param.payload;
            state.auth0Token = payload;
        },
        clearAuth0Token: function(state) {
            state.auth0Token = initialState5.auth0Token;
        },
        setAllowDelivery: function(state, param) {
            var payload = param.payload;
            state.allowDelivery = payload;
        },
        setFeatureFlagItems: function(state, param) {
            var payload = param.payload;
            state.featureFlagItems = payload;
        },
        setFeatureFlagHighlight: function(state, param) {
            var payload = param.payload;
            state.featureHighlight = payload;
        },
        resetApp: function(state) {
            return _object_spread_props(_object_spread({}, initialState5), {
                allowDelivery: state.allowDelivery
            });
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper5.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var appActions = appSlice.actions;
// src/slicers/BrowsableMenu.slice.ts
var import_toolkit6 = require("@reduxjs/toolkit");
var initialState6 = {
    restaurantList: []
};
var browsableMenuSlice = (0, import_toolkit6.createSlice)({
    name: "browsableMenu",
    initialState: initialState6,
    reducers: {
        setRestaurantList: function(state, param) {
            var payload = param.payload;
            state.restaurantList = payload;
        }
    }
});
var browsableMenuActions = browsableMenuSlice.actions;
// src/slicers/Location.slice.ts
var import_toolkit7 = require("@reduxjs/toolkit");
var import_next_redux_wrapper6 = require("next-redux-wrapper");
var initialState7 = {
    yextId: null,
    yextUrl: null,
    restaurant: null,
    loading: false,
    status: "stale",
    searchedRestaurantAddress: null,
    waitTime: null
};
var locationSlice = (0, import_toolkit7.createSlice)({
    name: "location",
    initialState: initialState7,
    reducers: {
        setYextId: function(state, param) {
            var payload = param.payload;
            state.yextId = payload;
        },
        setYextUrl: function(state, param) {
            var payload = param.payload;
            state.yextUrl = payload;
        },
        setRestaurant: function(state, param) {
            var payload = param.payload;
            state.restaurant = payload;
        },
        setLoading: function(state, param) {
            var payload = param.payload;
            state.loading = payload;
        },
        setStatus: function(state, param) {
            var payload = param.payload;
            state.status = payload;
        },
        setWaitTime: function(state, param) {
            var payload = param.payload;
            state.waitTime = payload;
        },
        setSearchedRestaurantAddress: function(state, param) {
            var payload = param.payload;
            state.searchedRestaurantAddress = payload;
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper6.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var locationActions = locationSlice.actions;
// src/slicers/Seating.slice.ts
var import_entity3 = require("@maverick/entity");
var import_toolkit8 = require("@reduxjs/toolkit");
var import_next_redux_wrapper7 = require("next-redux-wrapper");
var initialState8 = {
    seatingData: null,
    seatingDataModify: null
};
var seatingSlice = (0, import_toolkit8.createSlice)({
    name: "seating",
    initialState: initialState8,
    reducers: {
        setSeatingData: function(state, param) {
            var payload = param.payload;
            state.seatingData = !!payload ? new import_entity3.SeatingData(payload) : payload;
        },
        setSeatingDataModify: function(state, param) {
            var payload = param.payload;
            state.seatingDataModify = !!payload ? new import_entity3.SeatingData(payload) : payload;
        },
        resetSeatingDataModify: function(state) {
            if (!!state.seatingDataModify) {
                state.seatingData = new import_entity3.SeatingData(state.seatingDataModify);
                state.seatingDataModify = null;
            }
        }
    },
    extraReducers: function(builder) {
        builder.addCase(import_next_redux_wrapper7.HYDRATE, function(state, action) {
            return _object_spread({}, state, action);
        });
    }
});
var seatingActions = seatingSlice.actions;
// src/utils/StoreUtils.ts
var import_entity4 = require("@maverick/entity");
var import_redux_persist = require("redux-persist");
var import_traverse = __toESM(require("traverse"));
var dateTransform = (0, import_redux_persist.createTransform)(null, function(outboundState) {
    return (0, import_traverse.default)(outboundState).map(function(val) {
        var d = new Date(val);
        if (_instanceof(d, Date) && !isNaN(d) && d.toISOString() === val) return d;
        return val;
    });
});
var seatingTransform = (0, import_redux_persist.createTransform)(null, function(outboundState) {
    if (outboundState && !!outboundState.LocationId && !!outboundState.LocationName && !!outboundState.Guests) {
        return new import_entity4.SeatingData(outboundState);
    }
    return outboundState;
});
// src/store.ts
var getPersistConfig = function(key, blacklist) {
    var extraTransforms = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    return {
        key: key,
        storage: import_storage.default,
        blacklist: blacklist,
        transforms: [
            dateTransform,
            seatingTransform
        ].concat(_to_consumable_array(extraTransforms))
    };
};
var reducers = (0, import_toolkit9.combineReducers)({
    handoff: (0, import_redux_persist2.persistReducer)(getPersistConfig(HandoffSlice.name), HandoffSlice.reducer),
    checkout: (0, import_redux_persist2.persistReducer)(getPersistConfig(CheckoutSlice.name), CheckoutSlice.reducer),
    menu: (0, import_redux_persist2.persistReducer)(getPersistConfig(menuSlice.name), menuSlice.reducer),
    user: (0, import_redux_persist2.persistReducer)(getPersistConfig(userSlice.name), userSlice.reducer),
    app: (0, import_redux_persist2.persistReducer)(getPersistConfig(appSlice.name, [
        "loading",
        "processing"
    ]), appSlice.reducer),
    location: (0, import_redux_persist2.persistReducer)(getPersistConfig(locationSlice.name), locationSlice.reducer),
    browsableMenu: (0, import_redux_persist2.persistReducer)(getPersistConfig(menuSlice.name), browsableMenuSlice.reducer),
    seating: (0, import_redux_persist2.persistReducer)(getPersistConfig(seatingSlice.name, [], [
        seatingTransform
    ]), seatingSlice.reducer)
});
var middlewares = [
    import_redux_thunk.default
];
var isServer = typeof window === "undefined";
if (!isServer) {
    middlewares.push((0, import_redux_state_sync.createStateSyncMiddleware)({
        blacklist: [
            import_redux_persist2.PERSIST,
            import_redux_persist2.PURGE,
            "app/setShowSummary",
            "menu/setSelectedProduct",
            "menu/setEditedProduct",
            "menu/setEditedOptions",
            "menu/setOptionGroups",
            "menu/resetCustomize"
        ]
    }));
}
var store = (0, import_toolkit9.configureStore)({
    reducer: reducers,
    devTools: true,
    middleware: middlewares
});
var makeStore = function() {
    return store;
};
var persistor = (0, import_redux_persist2.persistStore)(store);
var StoreWrapper = (0, import_next_redux_wrapper8.createWrapper)(makeStore);
// src/operations/App.operations.ts
var AppOperations = {
    setLoading: function(loading) {
        return function(dispatch) {
            dispatch(appActions.setLoading(loading));
        };
    },
    setProcessing: function(processing) {
        return function(dispatch) {
            dispatch(appActions.setProcessing(processing));
        };
    },
    setRedirectRoute: function(route) {
        return function(dispatch) {
            dispatch(appActions.setRedirectRoute(route));
        };
    },
    clearRedirectRoute: function() {
        return function(dispatch) {
            dispatch(appActions.clearRedirectRoute());
        };
    },
    setRedirectRouteRewards: function(route) {
        return function(dispatch) {
            dispatch(appActions.setRedirectRouteRewards(route));
        };
    },
    clearRedirectRouteRewards: function() {
        return function(dispatch) {
            dispatch(appActions.clearRedirectRouteRewards());
        };
    },
    setRedirectRouteCheckout: function(route) {
        return function(dispatch) {
            dispatch(appActions.setRedirectRouteCheckout(route));
        };
    },
    clearRedirectRouteCheckout: function() {
        return function(dispatch) {
            dispatch(appActions.clearRedirectRouteCheckout());
        };
    },
    setRedirectRouteEdit: function(route) {
        return function(dispatch) {
            dispatch(appActions.setRedirectRouteEdit(route));
        };
    },
    clearRedirectRouteEdit: function() {
        return function(dispatch) {
            dispatch(appActions.clearRedirectRouteEdit());
        };
    },
    setUserToken: function(userToken) {
        return function(dispatch) {
            dispatch(appActions.setUserToken(userToken));
        };
    },
    clearUserToken: function() {
        return function(dispatch) {
            dispatch(appActions.clearUserToken());
        };
    },
    setShowSummary: function(showSummary) {
        return function(dispatch) {
            dispatch(appActions.setShowSummary(showSummary));
        };
    },
    setShowHowItWorks: function(showHosetShowHowItWorks) {
        return function(dispatch) {
            dispatch(appActions.setShowHowItWorks(showHosetShowHowItWorks));
        };
    },
    setAuth0Token: function(token) {
        return function(dispatch) {
            dispatch(appActions.setAuth0Token(token));
        };
    },
    clearAuth0Token: function() {
        return function(dispatch) {
            dispatch(appActions.clearAuth0Token());
        };
    },
    setAllowDelivery: function(allowDelivery) {
        return function(dispatch) {
            dispatch(appActions.setAllowDelivery(allowDelivery));
        };
    },
    setFeatureFlagItems: function(items) {
        return function(dispatch) {
            dispatch(appActions.setFeatureFlagItems(items));
        };
    },
    setFeatureHighlight: function(highlightItems) {
        return function(dispatch) {
            dispatch(appActions.setFeatureFlagHighlight(highlightItems));
        };
    },
    resetApp: function() {
        return function(dispatch) {
            dispatch(appActions.resetApp());
        };
    }
};
// src/operations/Checkout.operations.ts
var CheckoutOperations = {
    setBasket: function(basket) {
        return function(dispatch) {
            var action = checkoutActions.setBasket(basket);
            dispatch(action);
        };
    },
    setGiftCard: function(giftCard) {
        return function(dispatch) {
            dispatch(checkoutActions.setGiftCard(giftCard));
        };
    },
    setItemsNotTransferred: function(itemsNotTransferred) {
        return function(dispatch) {
            dispatch(checkoutActions.setItemsNotTransferred(itemsNotTransferred));
        };
    },
    clearItemsNotTransferred: function() {
        return function(dispatch) {
            dispatch(checkoutActions.clearItemsNotTransferred());
        };
    },
    setBillingScheme: function(billingScheme) {
        return function(dispatch) {
            dispatch(checkoutActions.setBillingScheme(billingScheme));
        };
    },
    setBasketCreationDate: function(basketCreationDate) {
        return function(dispatch) {
            dispatch(checkoutActions.setBasketCreationDate(basketCreationDate));
        };
    },
    setLastOrder: function(lastOrder) {
        return function(dispatch) {
            dispatch(checkoutActions.setLastOrder(lastOrder));
        };
    },
    setCurbsideInfo: function(curbsideInfo) {
        return function(dispatch) {
            dispatch(checkoutActions.setCurbsideInfo(curbsideInfo));
        };
    },
    resetCheckout: function() {
        return function(dispatch) {
            dispatch(checkoutActions.resetCheckout());
        };
    },
    setSignUpOption: function(loading) {
        return function(dispatch) {
            dispatch(checkoutActions.setSignUpOption(loading));
        };
    }
};
// src/operations/Handoff.operations.ts
var import_ui = require("@maverick/ui");
var import_entity5 = require("@maverick/entity");
var HandoffOperations = {
    setSelectedMethod: function(method) {
        return function(dispatch) {
            var action = handoffActions.setSelectedMethod(method);
            dispatch(action);
        };
    },
    setRestaurantList: function(restaurantList) {
        return function(dispatch) {
            dispatch(handoffActions.setRestaurantList(restaurantList));
        };
    },
    setSelectedRestaurant: function(selectedRestaurant) {
        return function(dispatch) {
            var _selectedRestaurant_extref_split, _selectedRestaurant_extref;
            var extrefParts = selectedRestaurant === null || selectedRestaurant === void 0 ? void 0 : (_selectedRestaurant_extref = selectedRestaurant.extref) === null || _selectedRestaurant_extref === void 0 ? void 0 : (_selectedRestaurant_extref_split = _selectedRestaurant_extref.split) === null || _selectedRestaurant_extref_split === void 0 ? void 0 : _selectedRestaurant_extref_split.call(_selectedRestaurant_extref, "_");
            var yextId = extrefParts && extrefParts.length >= 2 ? extrefParts[1] : selectedRestaurant === null || selectedRestaurant === void 0 ? void 0 : selectedRestaurant.extref;
            (0, import_ui.setCookie)(import_entity5.Cookies.FavoriteStore, yextId);
            dispatch(handoffActions.setSelectedRestaurant(selectedRestaurant));
        };
    },
    setSelectedRestaurantAddress: function(selectedRestaurantAddress) {
        return function(dispatch) {
            dispatch(handoffActions.setSelectedRestaurantAddress(selectedRestaurantAddress));
        };
    },
    setDeliveryAddress: function(deliveryAddress) {
        return function(dispatch) {
            dispatch(handoffActions.setDeliveryAddress(deliveryAddress));
        };
    },
    setSearchedAddresses: function(searchedAddresses) {
        return function(dispatch) {
            dispatch(handoffActions.setSearchedAddress(searchedAddresses));
        };
    },
    resetHandoff: function() {
        return function(dispatch) {
            dispatch(handoffActions.resetHandoff());
        };
    }
};
// src/operations/Menu.operations.ts
var MenuOperations = {
    setSelectedProduct: function(selectedProduct) {
        return function(dispatch) {
            dispatch(menuActions.setSelectedProduct(selectedProduct));
        };
    },
    setRestaurantMenu: function(restaurantMenu) {
        return function(dispatch) {
            dispatch(menuActions.setRestaurantMenu(restaurantMenu));
        };
    },
    setOptionGroups: function(optionGroups) {
        return function(dispatch) {
            dispatch(menuActions.setOptionGroups(optionGroups));
        };
    },
    selectOption: function(selectOption) {
        return function(dispatch) {
            dispatch(menuActions.selectOption(selectOption));
        };
    },
    resetCustomize: function() {
        return function(dispatch) {
            dispatch(menuActions.resetCustomize());
        };
    },
    setEditedOptions: function(editedOptions) {
        return function(dispatch) {
            dispatch(menuActions.setEditedOptions(editedOptions));
        };
    },
    setEditedProduct: function(editedProduct) {
        return function(dispatch) {
            dispatch(menuActions.setEditedProduct(editedProduct));
        };
    },
    setRedirectRouteMenu: function(redirectRouteMenu) {
        return function(dispatch) {
            dispatch(menuActions.setRedirectRouteMenu(redirectRouteMenu));
        };
    },
    setAddCartOrigin: function(addCartOrigin) {
        return function(dispatch) {
            dispatch(menuActions.setAddCartOrigin(addCartOrigin));
        };
    },
    resetMenu: function() {
        return function(dispatch) {
            dispatch(menuActions.resetMenu());
        };
    }
};
// src/operations/User.operations.ts
var UserOperations = {
    setUserRewards: function(rewardsInfo) {
        return function(dispatch) {
            dispatch(userActions.setUserRewards(rewardsInfo));
        };
    },
    setUserRedeemableRewards: function(redeemableRewardsInfo) {
        return function(dispatch) {
            dispatch(userActions.setUserRedeemableRewards(redeemableRewardsInfo));
        };
    },
    setUserRecentOrders: function(recentOrders) {
        return function(dispatch) {
            dispatch(userActions.setUserRecentOrders(recentOrders));
        };
    },
    setUserFaves: function(faves) {
        return function(dispatch) {
            dispatch(userActions.setUserFaves(faves));
        };
    },
    setUserFaveProducts: function(faveProducts) {
        return function(dispatch) {
            dispatch(userActions.setUserFaveProducts(faveProducts));
        };
    },
    setUserInfo: function(userInfo) {
        return function(dispatch) {
            dispatch(userActions.setUserInfo(userInfo));
        };
    },
    setUserLogged: function(setUserLogged) {
        return function(dispatch) {
            dispatch(userActions.setUserLogged(setUserLogged));
        };
    },
    setUserAuthLastInteraction: function(setUserAuthLastInteraction) {
        return function(dispatch) {
            dispatch(userActions.setUserAuthLastInteraction(setUserAuthLastInteraction));
        };
    },
    resetUser: function() {
        return function(dispatch) {
            dispatch(userActions.resetUser());
        };
    }
};
// src/operations/Location.operations.ts
var LocationOperations = {
    setYextId: function(yextId) {
        return function(dispatch) {
            dispatch(locationActions.setYextId(yextId));
        };
    },
    setYextUrl: function(yextUrl) {
        return function(dispatch) {
            dispatch(locationActions.setYextUrl(yextUrl));
        };
    },
    setRestaurant: function(restaurant) {
        return function(dispatch) {
            dispatch(locationActions.setRestaurant(restaurant));
        };
    },
    setLoading: function(loading) {
        return function(dispatch) {
            dispatch(locationActions.setLoading(loading));
        };
    },
    setStatus: function(status) {
        return function(dispatch) {
            dispatch(locationActions.setStatus(status));
        };
    },
    setWaitTime: function(waitTime) {
        return function(dispatch) {
            dispatch(locationActions.setWaitTime(waitTime));
        };
    },
    setSearchedRestaurantAddress: function(searchedRestaurantAddress) {
        return function(dispatch) {
            dispatch(locationActions.setSearchedRestaurantAddress(searchedRestaurantAddress));
        };
    }
};
// src/operations/Seating.operations.ts
var SeatingOperations = {
    setSeatingData: function(seatingData) {
        return function(dispatch) {
            dispatch(seatingActions.setSeatingData(seatingData));
        };
    },
    setSeatingDataModify: function(seatingDataModify) {
        return function(dispatch) {
            dispatch(seatingActions.setSeatingDataModify(seatingDataModify));
        };
    },
    resetSeatingDataModify: function() {
        return function(dispatch) {
            dispatch(seatingActions.resetSeatingDataModify());
        };
    }
};
// src/operations/BrowsableMenu.operations.ts
var BrowsableMenuOperations = {
    setRestaurantList: function(restaurantList) {
        return function(dispatch) {
            dispatch(browsableMenuActions.setRestaurantList(restaurantList));
        };
    }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
    AppOperations: AppOperations,
    BrowsableMenuOperations: BrowsableMenuOperations,
    CheckoutOperations: CheckoutOperations,
    CheckoutSlice: CheckoutSlice,
    HandoffOperations: HandoffOperations,
    HandoffSlice: HandoffSlice,
    LocationOperations: LocationOperations,
    MenuOperations: MenuOperations,
    SeatingOperations: SeatingOperations,
    StoreWrapper: StoreWrapper,
    UserOperations: UserOperations,
    appActions: appActions,
    appSlice: appSlice,
    browsableMenuActions: browsableMenuActions,
    browsableMenuSlice: browsableMenuSlice,
    checkoutActions: checkoutActions,
    getOptionContext: getOptionContext,
    getSubOptions: getSubOptions,
    handoffActions: handoffActions,
    locationActions: locationActions,
    locationSlice: locationSlice,
    menuActions: menuActions,
    menuSlice: menuSlice,
    normalizeAllOptions: normalizeAllOptions,
    persistor: persistor,
    seatingActions: seatingActions,
    seatingSlice: seatingSlice,
    store: store,
    userActions: userActions,
    userSlice: userSlice
});
