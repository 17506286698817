import {
	withDatasourceCheck,
	GetStaticComponentProps,
	LayoutServiceData,
	ComponentRendering,
	ImageField,
	Image as SitecoreImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useState, useEffect, useRef } from 'react';
import {
	CarouselDirection,
	CarouselDots,
	CarouselContainer,
	CarouselBanner,
	clickWithEnterOrSpace,
	CarouselArrow,
	CarouselSlider,
	Slide,
	ImageContainer,
} from '@maverick/cms';
import { CarouselResponse, SlideResponse } from './CarouselModel';
import { CarouselService } from 'src/services/Carousel.service';
import { GaEvent } from '@maverick/utils';
import Link from 'next/link';

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const data = await CarouselService.GetSlides<CarouselResponse>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);
	return { slides: data.body.item.children.results };
};

type CarouselRenderingProps = ComponentProps & {
	slides: SlideResponse[];
	rendering: {
		fields: {
			ArrowImage: ImageField;
		};
	};
};

export const CarouselRendering = (props: CarouselRenderingProps): JSX.Element => {
	const [isPlaying, setIsPlaying] = useState<boolean>(true);
	const [activeDot, setActiveDot] = useState<number>(1);
	const validSlides = props.slides.filter((slide) => slide.image?.src);
	const totalSlides = validSlides.length;
	const slides = [validSlides[totalSlides - 1], ...validSlides, validSlides[0]];
	const bannerRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);

	const handleBannerClick = () => {
		if (validSlides[activeDot]) {
			GaEvent.CarouselClick(validSlides[activeDot].name);
		}
	};

	const moveSlide = (direction: CarouselDirection) => {
		if (!sliderRef.current) return;

		sliderRef.current.scrollBy({
			left: sliderRef.current.offsetWidth * (direction == CarouselDirection.Right ? 1 : -1),
			behavior: 'smooth',
		});
	};

	const handleArrowClick = (direction: CarouselDirection) => {
		setIsPlaying(false);
		moveSlide(direction);
	};

	const handleDotClick = (index: number) => {
		if (!sliderRef.current) return;
		sliderRef.current.scrollTo({
			left: sliderRef.current.offsetWidth * index,
			behavior: 'smooth',
		});
		setIsPlaying(false);
		setTimeout(() => {
			document.getElementById(`Slide ${index}`)?.focus();
		}, 800);
	};
	useEffect(() => {
		if (!sliderRef.current) return;
		const firstSlideXPos = sliderRef.current.offsetWidth;
		sliderRef.current.scrollLeft = firstSlideXPos;
	}, []);

	useEffect(() => {
		let timer: NodeJS.Timeout;

		if (isPlaying) {
			timer = setTimeout(() => {
				moveSlide(CarouselDirection.Right);
			}, 5000);
		}

		return () => clearTimeout(timer);
	}, [isPlaying, activeDot]);

	useEffect(() => {
		let isScrolling: NodeJS.Timeout;
		const handleScroll = () => {
			clearTimeout(isScrolling);

			isScrolling = setTimeout(() => {
				if (!sliderRef.current) return;
				if (sliderRef.current.scrollLeft < 5) {
					const lastSlideXPos = sliderRef.current.scrollWidth - 2 * sliderRef.current.offsetWidth;
					sliderRef.current.scrollLeft = lastSlideXPos;
				} else if (
					sliderRef.current.scrollWidth - sliderRef.current.scrollLeft - sliderRef.current.offsetWidth <
					5
				) {
					const firstSlideXPos = sliderRef.current.offsetWidth;
					sliderRef.current.scrollLeft = firstSlideXPos;
				}
			}, 20);

			handleDotView();
		};
		const handleDotView = () => {
			if (!sliderRef.current) return;
			const slideIndex = Math.round(sliderRef.current.scrollLeft / sliderRef.current.offsetWidth);
			const dotIndex = () => {
				if (slideIndex == 0) return totalSlides;
				if (slideIndex == totalSlides + 1) return 1;
				return slideIndex;
			};

			setActiveDot(dotIndex());
		};
		sliderRef.current?.addEventListener('scroll', handleScroll);
		sliderRef.current?.addEventListener('touchmove', () => setIsPlaying(false));

		return () => {
			sliderRef.current?.removeEventListener('scroll', handleScroll);
			sliderRef.current?.removeEventListener('touchmove', () => setIsPlaying(false));
		};
	}, []);

	return (
		<CarouselContainer id='carousel-container' aria-live='polite'>
			<CarouselBanner id='carousel-banner' ref={bannerRef}>
				<CarouselSlider id='carousel-slider' ref={sliderRef}>
					{slides.map((slide, index) => (
						<Slide id={activeDot === index ? 'carousel-content' : undefined} key={index} aria-hidden={activeDot !== index ? 'true' : 'false'}>
							{slide.link?.jsonValue?.value?.href ? (
								<Link
									id={`Slide ${index}`}
									aria-label={`Slide ${index} - ${slide.image.alt}`}
									onClick={handleBannerClick}
									tabIndex={activeDot === index ? 0 : -1}
									href={slide.link.jsonValue.value.href}
									onFocus={() => setIsPlaying(false)}
									onBlur={() => setIsPlaying(true)}
								>
									<ImageContainer>
										<img alt={slide.image.alt} src={slide.image.src} />
									</ImageContainer>
								</Link>
							) : (
								<img alt={slide.image.alt} src={slide.image.src} />
							)}
						</Slide>
					))}
				</CarouselSlider>
				<CarouselArrow
					arrowImage={
						props.rendering?.fields?.ArrowImage?.value?.src ? (
							<SitecoreImage field={props.rendering?.fields?.ArrowImage} />
						) : undefined
					}
					direction={CarouselDirection.Left}
					onClick={() => handleArrowClick(CarouselDirection.Left)}
					ariaLabel='Previous slide'
				/>
				<CarouselArrow
					arrowImage={
						props.rendering?.fields?.ArrowImage?.value?.src ? (
							<SitecoreImage field={props.rendering?.fields?.ArrowImage} />
						) : undefined
					}
					direction={CarouselDirection.Right}
					onClick={() => handleArrowClick(CarouselDirection.Right)}
					ariaLabel='Next slide'
				/>
			</CarouselBanner>
			<CarouselDots
				onClick={() => {
					setIsPlaying(!isPlaying);
				}}
				onKeyDown={(event) =>
					clickWithEnterOrSpace(event, () => {
						setIsPlaying(!isPlaying);
					})
				}
				isPlaying={isPlaying}
			>
				<ol>
					{validSlides.map((_, index) => (
						<li key={index} className={index + 1 === activeDot ? 'slick-active' : ''}>
							<button
								onClick={() => handleDotClick(index + 1)}
								onKeyDown={(event) => clickWithEnterOrSpace(event, () => handleDotClick(index + 1))}
								aria-label={`Slide ${index + 1} of ${totalSlides}`}
								aria-current={index + 1 === activeDot ? 'true' : 'false'}
							/>
						</li>
					))}
				</ol>
			</CarouselDots>
		</CarouselContainer>
	);
};

export default withDatasourceCheck()<CarouselRenderingProps>(CarouselRendering);
