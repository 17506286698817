import { useIsMobile } from '@maverick/hooks';
import { FC, useRef, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SteakStoryScrollCta } from './components';

const slideInLeft = keyframes`
    0% {transform: translate(-100%, 0);}
    20% {transform: translate(0, 0);}
    60% {transform: translate(-1%, 0);}
    100% {transform: translate(0, 0);}
`;
const slideInRight = keyframes`
    0% {transform: translate(100%, 0);}
    20% {transform: translate(0, 0);}
    60% {transform: translate(2%, 0);}
    100% {transform: translate(0, 0);}
`;
const slideOutLeft = keyframes`
    0% {transform: translate(0, 0);}
    100% {transform: translate(-100%, 0);}
`;
const slideOutRight = keyframes`
    0% {transform: translate(0, 0);}
    100% {transform: translate(100%, 0);}
`;

const slideInLeftMobile = keyframes`
	0% {top: -100%;}
	20% {top: 0;}
	60% {top: -1%;}
	100% {top: 0;}
`;
const slideInRightMobile = keyframes`
	0% {top: 100%;}
	20% {top: 50%;}
	60% {top: 52%;}
	100% {top: 50%;}
`;
const slideOutLeftMobile = keyframes`
	0% {top: 0;}
	100% {top: -100%;}
`;
const slideOutRightMobile = keyframes`
	0% {top: 50%;}
	100% {top: 100%;}
`;

const Container = styled.section`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/bg-texture-wp.webp)
		repeat ${({ theme }) => theme.colors.primary.medium};
	position: relative;
	z-index: 2;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 33px;
		top: -3px;
		left: 0;
		background: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Horizontal-Line/section-border-top.webp')
			repeat-x;
		filter: brightness(0) invert(1);
		opacity: 0.3;
		z-index: 4;
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: #0002;
		z-index: 3;
	}
`;
const ContentWrapper = styled.div`
	height: calc(100vh + 400px);
`;
const Content = styled.div`
	color: ${({ theme }) => theme.colors.neutral.white};
	text-align: center;
	max-width: 670px;
	position: relative;
	z-index: 4;
	padding: 100px 0;
	margin: 200px auto 0;

	h2 {
		margin: 0;
		text-transform: uppercase;
		font-size: 6rem;
		line-height: 1;
	}

	p {
		margin: 32px 0 0;
		font-size: 1.5rem;
	}

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 40px;
		background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/shared/border-small-top-red-wp.webp)
			center no-repeat;
		top: 0;
		left: 0;
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 40px;
		bottom: 0;
		left: 0;
		background: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Horizontal-Line/section-border-top-beige.webp')
			repeat-x;
		filter: brightness(0) opacity(0.3);
	}

	@media (max-width: 768px) {
		padding: 100px 32px;

		h2 {
			font-size: 3.4rem;
		}

		p {
			font-size: 1rem;
		}
	}
`;
const PanelsWrapper = styled.div`
	position: absolute;
	z-index: 5;
	width: 100%;
	height: calc(100vh - 62px);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;
const Panel = styled.div<{ direction: 'right' | 'left'; show: boolean }>`
	position: absolute;
	width: 50%;
	height: 100vh;
	transition: all 0.5s;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 6;
	overflow: hidden;
	background-position: center;
	background-size: cover;

	p {
		opacity: 0;
		transform: translate(0, 20px);
		transition: all ease-in-out 0.5s;
		transition-delay: 0.5s;
	}

	@media (max-width: 768px) {
		top: 62px;
		width: 100%;
		height: 50vh;
		left: 0;

		video {
			display: none;
		}
	}

	${({ show }) =>
		show &&
		css`
			p {
				opacity: 1;
				transform: translate(0, 0);
			}
		`}

	${({ direction }) => {
		if (direction === 'right') {
			return css`
				background-image: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-greatness/greatness-grilled.webp);
				right: 0;
				transform: translate(100%, 0);

				@media (max-width: 768px) {
					top: 100%;
					transform: unset;
				}
			`;
		}

		if (direction === 'left') {
			return css`
				background-image: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-greatness/greatness-seasoning.webp);
				left: 0;
				transform: translate(-100%, 0);

				@media (max-width: 768px) {
					top: -100%;
					transform: unset;
				}
			`;
		}

		return null;
	}}

	${({ show, direction }) => {
		if (show) {
			if (direction === 'left') {
				return css`
					animation: ${slideInLeft} 1s ease-out forwards;

					@media (max-width: 768px) {
						animation: ${slideInLeftMobile} 1s ease-out forwards;
					}
				`;
			}

			if (direction === 'right') {
				return css`
					animation: ${slideInRight} 1s ease-out forwards;

					@media (max-width: 768px) {
						animation-name: ${slideInRightMobile};
					}
				`;
			}
		} else {
			if (direction === 'left') {
				return css`
					animation: ${slideOutLeft} 0.5s ease-out forwards;

					@media (max-width: 768px) {
						animation-name: ${slideOutLeftMobile};
					}
				`;
			}

			if (direction === 'right') {
				return css`
					animation: ${slideOutRight} 0.5s ease-out forwards;

					@media (max-width: 768px) {
						animation-name: ${slideOutRightMobile};
					}
				`;
			}
		}
		return null;
	}}

    video {
		position: absolute;
		z-index: 0;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		transform: translate(-50%, -50%);
		background-size: cover;
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.4);
	}

	@media (max-width: 768px) {
		video {
			display: none;
		}
	}

	@media (max-height: 670px) {
		${({ direction }) => {
			if (direction === 'right') {
				return css`
					${PanelContent} {
						padding: 0 0 100px;
					}
				`;
			}

			return null;
		}}
	}
`;
const PanelContent = styled.div`
	text-align: center;
	color: ${({ theme }) => theme.colors.neutral.white};
	position: relative;
	z-index: 8;

	h3 {
		text-transform: uppercase;
		font-size: 4rem;
		margin: 0;
	}

	p {
		margin: 24px 0 0;
		padding: 0 20%;
		font-size: 1.5rem;
	}

	@media (max-width: 768px) {
		h3 {
			font-size: 2.4rem;
		}

		p {
			font-size: 1.2rem;
			padding: 0 64px;
		}
	}
`;
const ScrollCtaWrapper = styled.div`
	position: absolute;
	bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 6;

	@media (max-width: 768px) {
		bottom: 10px;
	}
`;

interface SteakStoryGreatnessProps {
	onScrollCtaClick: () => void;
}
export const SteakStoryGreatness: FC<SteakStoryGreatnessProps> = ({ onScrollCtaClick }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const panelsWrapperRef = useRef<HTMLDivElement>(null);
	const [showPanels, setShowPanels] = useState<boolean>(false);
	const isMobile = useIsMobile(768);

	const seasoningRef = useRef<HTMLVideoElement>(null);
	const grilledRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const onScroll = () => {
			if (!containerRef.current || !panelsWrapperRef.current) return;

			const scrolled = document.documentElement.scrollTop;
			const windowHeight = window.innerHeight;
			const containerTop = containerRef.current.offsetTop;
			const containerHeight = containerRef.current.clientHeight;
			const start = containerTop;
			const end = start + containerHeight - windowHeight;

			if (scrolled < start) {
				panelsWrapperRef.current.style.position = 'absolute';
				panelsWrapperRef.current.style.top = isMobile ? '62px' : '0';
				panelsWrapperRef.current.style.bottom = 'unset';
			} else if (scrolled >= start && scrolled <= end) {
				panelsWrapperRef.current.style.position = 'fixed';
				panelsWrapperRef.current.style.top = isMobile ? '62px' : '0';
				panelsWrapperRef.current.style.bottom = '0';

				if (!isMobile) {
					if (seasoningRef.current?.paused) seasoningRef.current.play();
					if (grilledRef.current?.paused) grilledRef.current.play();
				}
			} else {
				panelsWrapperRef.current.style.position = 'absolute';
				panelsWrapperRef.current.style.top = 'unset';
				panelsWrapperRef.current.style.bottom = '0';
			}

			setShowPanels(scrolled > start);
		};

		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<Container ref={containerRef}>
			<ContentWrapper>
				<Content>
					<h2>
						Two paths to
						<br />
						greatness
					</h2>
					<p>
						At Outback Steakhouse, there are 2 perfect, tried and true cooking preparations we live by.
						Explore our preparations to see how we'll get yours done right.
					</p>
				</Content>
			</ContentWrapper>
			<PanelsWrapper ref={panelsWrapperRef}>
				<Panel show={showPanels} direction='left'>
					<PanelContent>
						<h3>
							Classic seasoned
							<br />
							and seared
						</h3>
						<p>
							Your steak is powdered with our famous spice & herbs blend, then seared on a grill for a
							packed punch of flavor.
						</p>
					</PanelContent>
					<video loop ref={seasoningRef} muted>
						<source
							src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-greatness/seasoning.mp4'
							type='video/mp4'
						/>
					</video>
				</Panel>

				<Panel show={showPanels} direction='right'>
					<PanelContent>
						<h3>Char-grilled</h3>
						<p>Your big, bold, bone-in steak is seasoned and then grilled for full, rich flavor.</p>
					</PanelContent>
					<video loop ref={grilledRef} muted>
						<source
							src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-greatness/grilled.mp4'
							type='video/mp4'
						/>
					</video>
				</Panel>

				<ScrollCtaWrapper onClick={onScrollCtaClick}>
					<SteakStoryScrollCta label='Keep going' />
				</ScrollCtaWrapper>
			</PanelsWrapper>
		</Container>
	);
};
