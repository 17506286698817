import { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { SteakStoryCookStage } from '../SteakStory.Cook';

const Container = styled.div`
	width: 42px;
	height: 473px;
	position: absolute;
	right: 60px;
	top: 0;
	bottom: 0;
	margin: auto 0;

	@media (max-width: 768px) {
		display: none;
	}
`;

const Thermometer = styled.div`
	position: relative;
	width: 42px;
	height: 473px;
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-cook/thermometer-wp.webp)
		center no-repeat;
	z-index: 4;
`;

const Bulb = styled.div`
	position: absolute;
	bottom: 2px;
	left: 6px;
	right: 2px;
	width: 34px;
	height: 34px;
	border-radius: 100%;
	background: #931a1a;
	z-index: 3;
`;

const Stem = styled.div`
	position: absolute;
	left: 15px;
	right: 10px;
	bottom: 34px;
	max-height: 435px;
	background: #931a1a;
	z-index: 3;
	border-radius: 8px 8px 0 0;
`;

const Description = styled.div<{ stage: SteakStoryCookStage }>`
	position: absolute;
	right: calc(100% + 12px);
	width: 200px;
	font-family: 'jenna-sue';
	font-size: 2rem;
	transform: rotate(-10deg);
	z-index: 3;
	color: ${({ theme }) => theme.colors.neutral.white};
	text-align: right;
	transition: all ease 0.1s;

	${({ stage }) => {
		if (stage.id === 'main') {
			return css`
				display: none;
			`;
		}

		if (stage.id === 'rare') {
			return css`
				top: 348px;
			`;
		}

		if (stage.id === 'medium-rare') {
			return css`
				top: 261px;
			`;
		}

		if (stage.id === 'medium') {
			return css`
				top: 174px;
			`;
		}

		if (stage.id === 'medium-well') {
			return css`
				top: 87px;
			`;
		}

		if (stage.id === 'well-done') {
			return css`
				top: 0;
			`;
		}

		return null;
	}}
`;

interface SteakStoryThermometerProps {
	start: number;
	end: number;
	stage: SteakStoryCookStage;
}
export const SteakStoryThermometer: FC<SteakStoryThermometerProps> = ({ start, end, stage }) => {
	const stemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const onScroll = () => {
			if (!start || !end || !stemRef.current) return;

			const scrolled = document.documentElement.scrollTop;
			const innerScrolled = scrolled - start;
			const fraction = innerScrolled / (end - start);
			const stemHeight = 435 * fraction;
			stemRef.current.style.height = `${stemHeight}px`;
		};

		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [start, end]);

	return (
		<Container>
			<Thermometer />
			<Bulb />
			<Stem ref={stemRef} />
			<Description stage={stage} dangerouslySetInnerHTML={{ __html: stage.subtitle ?? '' }} />
		</Container>
	);
};
