
import { LocationPopUpContent } from './LocationPopUpContent';
import { connect, ConnectedProps } from 'react-redux';
import { LocationOperations, RootState } from '@maverick/store';

const mapStateToProps = (state: RootState) => ({
	seatingData: state.seating.seatingData,
	waitTime: state.location.waitTime,
	restaurant: state.location.restaurant,
});

const mapDispatchToProps = {
	setWaitTime: LocationOperations.setWaitTime,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type LocationPopUpContentReduxProps = ConnectedProps<typeof connector>;
const LocationPopUpContentConnected = connector(LocationPopUpContent);
export { LocationPopUpContentConnected as LocationPopUpContent };
