import { FC } from 'react';
import * as Styled from './BasketButton.styled';
import { Icon, Tooltip } from '@maverick/ui';
import { useTheme } from 'styled-components';
import { GaEvent } from '@maverick/utils';
import { ReduxProps } from '.';
import { handleKeyDown } from '../ProfileButton/ProfileButton.utils';
import { useRouter } from 'next/router';
import { Helmet } from 'react-helmet';

export interface BasketButtonProps extends ReduxProps { }

export const BasketButton: FC<BasketButtonProps> = ({ basket, setShowSummary, restaurant }) => {
	const { orderUi } = useTheme();
	const basketBadge = !!basket?.products.length;
	const redirectUrl = (restaurant && restaurant.slug)? `/menu/${restaurant.slug}` : '';
	const router = useRouter();

	const handleClick = () => {
		const productCount = basket?.products.length || 0;
		GaTopNavigationEvent('Cart');
		if (productCount > 0) {
			setShowSummary(true);
		}
		router.push(`/order${redirectUrl}`);
	};

	const GaTopNavigationEvent = (label: string) => {
		const helmetState = Helmet.peek();
		const pageTitle = helmetState?.title || document.title;
		GaEvent.BasketTopNavigation(label, basket?? null, [], restaurant?.brand ?? 'not-set', pageTitle);
	};

	const renderBasketButton = () => {
		const productCount = basket?.products.length;
		const ariaLabel = `Basket${productCount ? ` ${productCount} ${productCount === 1 ? 'item' : 'items'}` : ''}`;

		return (
			<Styled.BasketButton
				onClick={handleClick}
				aria-label={ariaLabel}
				onKeyDown={(ev) => handleKeyDown(ev, handleClick)}
			>
				<Styled.Icon data-testid="basket-icon">
					<Icon icon={orderUi.icons.iconBasketHeader} ariaLabel="view basket" customSize />
					{basketBadge && <Styled.BasketBadge data-testid="basket-badge">{productCount}</Styled.BasketBadge>}
				</Styled.Icon>
			</Styled.BasketButton>
		);
	};

	return (
		<Tooltip disabled grabFocus content={<></>} id="basket-icon-tooltip" variant="light">
			{renderBasketButton()}
		</Tooltip>
	);
};
